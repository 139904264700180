<template>
    <div class="dropdown float-right">
      <b-dropdown
          no-caret
          right
          toggle-class="p-0 mr-1"
          variant="link"
      >
        <template #button-content>
          <feather-icon
              class="align-middle text-body"
              icon="MoreVerticalIcon"
              size="16"
          />
        </template>
        <b-dropdown-item
           @click="isProfileSidebar = true"
        >
          <feather-icon
              class="mr-50"
              icon="UserIcon"
              size="16"
          />
          <span class="font-weight-bolder"> View profile </span>
        </b-dropdown-item>
        <b-dropdown-item
          @click="isChatSidebar = true"
        >
          <feather-icon
              class="mr-50"
              icon="SendIcon"
              size="16"
          />
          <span class="font-weight-bolder"> Send Message </span>
        </b-dropdown-item>
      </b-dropdown> 

      <ProfileSidebar
          ref="UserProfileSidebar"
          v-if="isProfileSidebar"
          :is-profile-sidebar.sync="isProfileSidebar"
          :user-details="userDetails"
          :unavailability-times="unavailabilityTimes"
          @toggleSideBars="toggleSideBars()"
      />

      <!-- <ChatSidebar
          ref="UserProfileSidebar"
          :is-chat-sidebar.sync="isChatSidebar"
          @toggleSideBars="toggleChatSideBars()"
      /> -->

    </div>

</template>

<script>

import ProfileSidebar from '../sidebars/profileSideBar.vue'
//import ChatSidebar from '../sidebars/chatSideBar.vue'

import {BDropdown, BDropdownItem} from 'bootstrap-vue'
import TimesheetAPI from '@/apis/modules/timesheet'

export default {
  components: {
    BDropdown, BDropdownItem, ProfileSidebar,
    // ChatSidebar
  },
props:{
  userData:{
    type: Object
  }
},
data() {
  return {
    isProfileSidebar: false,
    isChatSidebar: false,
    userDetails: {},
    userDetailsUnavailability: {},
    unavailabilityTimes:[],
    arrayCheck:[]
  }
},
watch: {
  isProfileSidebar(val){
    if(val) this.getUserDetails()
  }
},
methods: {
  toggleSideBars() {
    this.isProfileSidebar = !this.isProfileSidebar
  },
  async getUserDetails(){
    const user = (await TimesheetAPI.getUserDetails(this.userData.id)).data.data
    this.userDetails = user.user[0]
    this.userDetailsUnavailability = user
    this.getUnavailability()
  },
  getUnavailability(){
      const today = new Date();
      const day = today.getDay()
      const todayTimes = []
      this.userDetailsUnavailability.un_availability.forEach(function(key){
        if(key.day_number == day){
          key.times.forEach(function(key){
            todayTimes.push(key)
          })
        }
      })
      this.unavailabilityTimes = todayTimes
      
  },
  toggleChatSideBars() {
    this.isChatSidebar = !this.isChatSidebar
  },
},
mounted(){
  // this.getUserDetails()
}

}
</script>

<style scoped>

</style>
