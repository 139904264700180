<template>
  <div>
    <main class="">
      <div>
        <div class="card">
          <div class="row">
            <div class="col-12 col-lg-5 col-xl-3 border-right">
              <div class="d-none d-md-block">
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1">
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <feather-icon icon="SearchIcon" />
                      </b-input-group-prepend>
                      <b-form-input
                        v-model="filters['search']"
                        id="icons-search"
                        placeholder="Search"
                      />
                    </b-input-group>
                  </div>
                </div>
                <!-- <div><hr></div> -->
              </div>
              <b-overlay :show="tableLoading" rounded="sm" class="mt-1">
                <vue-perfect-scrollbar
                  v-if="users.length"
                  class="scrollable-container media-list scroll-area"
                  style="height: 640px; solid: #e5e5e5"
                >
                  <b-table
                    ref="usersList"
                    :fields="fieldsUserList"
                    :items="getUsers"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    thead-class="d-none"
                    hover
                    primary-key="id"
                    responsive
                    @row-clicked="getUnSubmittedTimesheetsOfUser"
                  >
                    <template #cell(user)="data">
                      <div class="d-flex">
                        <b-avatar
                          v-if="data.item.avatar"
                          size="40"
                          :src="data.item.avatar"
                        />
                        <b-avatar
                          v-else
                          size="40"
                          :src="getUserImage(data.item.id)"
                        />

                        <div class="ml-1" style="padding-top: 2px">
                          <div>
                            <span
                              class="ml-1 font-weight-bolder text-dark mb-0"
                              >{{ data.item.user }}</span
                            >
                          </div>
                          <div>
                            <span
                              style="
                                font-family: Mulish;
                                font-size: 12px;
                                color: #949494;
                              "
                              class="ml-1"
                              >{{ data.item.count }} timesheets</span
                            >
                          </div>
                        </div>
                      </div>

                      <!--                        <b-row class="center text-primary">-->
                      <!--                            <b-avatar v-if="data.item.avatar"-->
                      <!--                              size="40"-->
                      <!--                              :src="data.item.avatar"-->
                      <!--                            />-->
                      <!--                            <b-avatar-->
                      <!--                              v-else-->
                      <!--                              size="40"-->
                      <!--                              :src="getUserImage(data.item.id)"-->
                      <!--                            />-->
                      <!--                            <span class="ml-1 font-weight-bolder  text-dark mb-0" >{{data.item.user}}</span>-->
                      <!--                        </b-row>-->
                      <!--                          <div class="" style="padding-top: 0px;">-->
                      <!--                            <span class="ml-3 font-weight-bolder text-dark" >{{data.item.count}} timesheets</span>-->
                      <!--                          </div>-->
                    </template>
                  </b-table>
                </vue-perfect-scrollbar>
                <div class="" v-else>
                  <b-overlay
                    :show="unsubmittedLoading"
                    rounded="sm"
                    class="mt-1"
                  >
                    <div style="height: 100px"></div>
                    <div class="text-center">
                      <svg
                        width="100"
                        height="100"
                        viewBox="0 0 50 50"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M25 0C38.8072 0 50 11.1928 50 25C50 38.8072 38.8072 50 25 50C11.1928 50 0 38.8072 0 25C0 11.1928 11.1928 0 25 0Z"
                          fill="#CCE6F4"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M34.8938 12.5996C35.6281 12.5996 36.2235 13.1951 36.2235 13.9294C36.2235 14.6637 35.6281 15.2592 34.8938 15.2592C34.1592 15.2592 33.564 14.6637 33.564 13.9294C33.564 13.1951 34.1592 12.5996 34.8938 12.5996Z"
                          fill="white"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M38.6171 8.34375C39.6453 8.34375 40.4788 9.17726 40.4788 10.2055C40.4788 11.2336 39.6453 12.0672 38.6171 12.0672C37.5889 12.0672 36.7554 11.2336 36.7554 10.2055C36.7554 9.17726 37.5889 8.34375 38.6171 8.34375Z"
                          fill="white"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M33.0319 7.8125C33.3258 7.8125 33.5638 8.05053 33.5638 8.34441C33.5638 8.6383 33.3258 8.87633 33.0319 8.87633C32.738 8.87633 32.5 8.6383 32.5 8.34441C32.5 8.05053 32.738 7.8125 33.0319 7.8125Z"
                          fill="white"
                        />
                        <path
                          d="M34.701 20.1504C32.7913 20.1504 31.2363 21.7053 31.2363 23.615C31.2363 25.5247 32.7913 27.0797 34.701 27.0797C36.6107 27.0797 38.1656 25.5247 38.1656 23.615C38.1656 21.7053 36.6107 20.1504 34.701 20.1504ZM34.701 25.6938C33.5548 25.6938 32.6222 24.7612 32.6222 23.615C32.6222 22.4689 33.5548 21.5362 34.701 21.5362C35.8471 21.5362 36.7798 22.4689 36.7798 23.615C36.7798 24.7612 35.847 25.6938 34.701 25.6938Z"
                          fill="black"
                        />
                        <path
                          d="M15.3975 20.1504C13.4864 20.1504 11.9329 21.7053 11.9329 23.615C11.9329 25.5247 13.4878 27.0797 15.3975 27.0797C17.3072 27.0797 18.8622 25.5247 18.8622 23.615C18.8622 21.7053 17.3086 20.1504 15.3975 20.1504ZM15.3975 25.6938C14.2514 25.6938 13.3187 24.7612 13.3187 23.615C13.3187 22.4689 14.2514 21.5362 15.3975 21.5362C16.5436 21.5362 17.4763 22.4689 17.4763 23.615C17.4763 24.7612 16.5436 25.6938 15.3975 25.6938Z"
                          fill="black"
                        />
                        <path
                          d="M34.7007 28.4648C33.5601 28.4648 32.4431 28.7767 31.4702 29.3657C31.1445 29.5652 31.0392 29.9907 31.2374 30.3178C31.4383 30.6449 31.8624 30.7488 32.1895 30.5506C32.9447 30.0918 33.8123 29.8507 34.7007 29.8507C37.3754 29.8507 39.5512 32.0265 39.5512 34.7013C39.5512 35.0838 39.8617 35.3942 40.2442 35.3942C40.6267 35.3942 40.9372 35.0838 40.9372 34.7013C40.9371 31.2629 38.139 28.4648 34.7007 28.4648Z"
                          fill="black"
                        />
                        <path
                          d="M18.528 29.3643C17.5565 28.7767 16.4395 28.4648 15.2989 28.4648C11.8606 28.4648 9.0625 31.2629 9.0625 34.7013C9.0625 35.0838 9.37296 35.3942 9.75546 35.3942C10.138 35.3942 10.4484 35.0838 10.4484 34.7013C10.4483 32.0265 12.6242 29.8507 15.2989 29.8507C16.1873 29.8507 17.0548 30.0918 17.8087 30.5492C18.1344 30.7474 18.5613 30.6434 18.7608 30.3163C18.9604 29.9893 18.855 29.5638 18.528 29.3643Z"
                          fill="black"
                        />
                        <path
                          d="M24.9999 25.6934C20.0329 25.6934 15.9917 29.7345 15.9917 34.7015C15.9917 35.084 16.3022 35.3945 16.6847 35.3945C17.0672 35.3945 17.3776 35.084 17.3776 34.7015C17.3776 30.4982 20.7965 27.0793 24.9999 27.0793C29.2032 27.0793 32.6221 30.4982 32.6221 34.7015C32.6221 35.084 32.9326 35.3945 33.3151 35.3945C33.6976 35.3945 34.008 35.084 34.008 34.7015C34.008 29.7345 29.9668 25.6934 24.9999 25.6934Z"
                          fill="#1B9AAA"
                        />
                        <path
                          d="M25 14.6055C22.3253 14.6055 20.1494 16.7813 20.1494 19.456C20.1494 22.1308 22.3252 24.3066 25 24.3066C27.6747 24.3066 29.8505 22.1307 29.8505 19.456C29.8505 16.7813 27.6747 14.6055 25 14.6055ZM25 22.9207C23.0903 22.9207 21.5353 21.3657 21.5353 19.456C21.5353 17.5463 23.0903 15.9914 25 15.9914C26.9097 15.9914 28.4646 17.5463 28.4646 19.456C28.4646 21.3657 26.9097 22.9207 25 22.9207Z"
                          fill="#1B9AAA"
                        />
                        <path
                          d="M19.0234 13.4453C19.0234 13.6246 18.9039 13.7441 18.7246 13.7441H16.9316V15.5371C16.9316 15.7164 16.8121 15.8359 16.6328 15.8359C16.4535 15.8359 16.334 15.7164 16.334 15.5371V13.7441H14.541C14.3617 13.7441 14.2422 13.6246 14.2422 13.4453C14.2422 13.266 14.3617 13.1465 14.541 13.1465H16.334V11.3535C16.334 11.1742 16.4535 11.0547 16.6328 11.0547C16.8121 11.0547 16.9316 11.1742 16.9316 11.3535V13.1465H18.7246C18.9039 13.1465 19.0234 13.266 19.0234 13.4453Z"
                          fill="#1B9AAA"
                          stroke="#1B9AAA"
                          stroke-width="0.5"
                        />
                        <mask
                          id="mask0_1872_13634"
                          style="mask-type: alpha"
                          maskUnits="userSpaceOnUse"
                          x="14"
                          y="11"
                          width="6"
                          height="5"
                        >
                          <path
                            d="M19.0234 13.4453C19.0234 13.6246 18.9039 13.7441 18.7246 13.7441H16.9316V15.5371C16.9316 15.7164 16.8121 15.8359 16.6328 15.8359C16.4535 15.8359 16.334 15.7164 16.334 15.5371V13.7441H14.541C14.3617 13.7441 14.2422 13.6246 14.2422 13.4453C14.2422 13.266 14.3617 13.1465 14.541 13.1465H16.334V11.3535C16.334 11.1742 16.4535 11.0547 16.6328 11.0547C16.8121 11.0547 16.9316 11.1742 16.9316 11.3535V13.1465H18.7246C18.9039 13.1465 19.0234 13.266 19.0234 13.4453Z"
                            fill="white"
                          />
                        </mask>
                        <g mask="url(#mask0_1872_13634)">
                          <path
                            d="M19.0234 13.4453C19.0234 13.6246 18.9039 13.7441 18.7246 13.7441H16.9316V15.5371C16.9316 15.7164 16.8121 15.8359 16.6328 15.8359C16.4535 15.8359 16.334 15.7164 16.334 15.5371V13.7441H14.541C14.3617 13.7441 14.2422 13.6246 14.2422 13.4453C14.2422 13.266 14.3617 13.1465 14.541 13.1465H16.334V11.3535C16.334 11.1742 16.4535 11.0547 16.6328 11.0547C16.8121 11.0547 16.9316 11.1742 16.9316 11.3535V13.1465H18.7246C18.9039 13.1465 19.0234 13.266 19.0234 13.4453Z"
                            fill="#1B9AAA"
                          />
                          <mask
                            id="mask1_1872_13634"
                            style="mask-type: alpha"
                            maskUnits="userSpaceOnUse"
                            x="14"
                            y="11"
                            width="6"
                            height="5"
                          >
                            <path
                              d="M19.0234 13.4453C19.0234 13.6246 18.9039 13.7441 18.7246 13.7441H16.9316V15.5371C16.9316 15.7164 16.8121 15.8359 16.6328 15.8359C16.4535 15.8359 16.334 15.7164 16.334 15.5371V13.7441H14.541C14.3617 13.7441 14.2422 13.6246 14.2422 13.4453C14.2422 13.266 14.3617 13.1465 14.541 13.1465H16.334V11.3535C16.334 11.1742 16.4535 11.0547 16.6328 11.0547C16.8121 11.0547 16.9316 11.1742 16.9316 11.3535V13.1465H18.7246C18.9039 13.1465 19.0234 13.266 19.0234 13.4453Z"
                              fill="white"
                            />
                          </mask>
                          <g mask="url(#mask1_1872_13634)"></g>
                        </g>
                      </svg>

                      <h5 class="mt-2">No Users</h5>
                      <div style="height: 100px"></div>
                    </div>
                  </b-overlay>
                </div>
              </b-overlay>
            </div>

            <div class="col-12 col-xl-9">
              <div
                class="d-flex border-bottom align-items-center mb-1"
                v-if="!haveData"
              >
                <div class="mb-1">
                  <div>
                    <div class="d-flex">
                      <div class="position-relative">
                        <b-avatar
                          size="40"
                          :src="getUserImage(selectedUser.id)"
                          class="mr-1"
                        >
                        </b-avatar>
                        <strong>{{ selectedUser.user }}</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="ml-auto d-flex align-items-center">
                  <div class="border-right mr-1">
                    <b-button
                      size="20"
                      variant="outline-white"
                      class="bg-dark text-white"
                      :disabled="unsubmittedIds.length == 0"
                      @click="approveAllUnsubmitted"
                    >
                      Approve & Submit All
                    </b-button>
                  </div>
                  <div>
                    <span
                      class="border-right m-1"
                      style="
                        font-size: x-large;
                        border-right: 2px solid #ebe9f1 !important;
                      "
                    ></span>
                  </div>
                  <div class="">
                    <DropdownMenue :user-data="selectedUser" />
                  </div>
                </div>
              </div>
              <div class="" v-if="haveData">
                <b-overlay :show="unsubmittedLoading" rounded="sm" class="mt-1">
                  <div style="height: 100px"></div>
                  <div class="text-center">
                    <svg
                      style="margin: auto"
                      width="220"
                      height="220"
                      viewBox="0 0 160 160"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M80 0C124.183 0 160 35.817 160 80C160 124.183 124.183 160 80 160C35.817 160 0 124.183 0 80C0 35.817 35.817 0 80 0Z"
                        fill="#F0F8FC"
                      />
                      <path
                        d="M66.1893 87.4937H92.1617M66.1893 100.484H83.5057"
                        stroke="#1B9AAA"
                        stroke-width="3"
                        stroke-linecap="round"
                      />
                      <path
                        d="M81.3234 40.7457H54.3247C50.8315 40.7457 48 43.5765 48 47.0667V116.679C48 120.169 50.8315 123 54.3247 123H102.28C105.778 123 108.609 120.169 108.609 116.679V68.0304"
                        stroke="#171822"
                        stroke-width="3"
                        stroke-linecap="round"
                      />
                      <path
                        d="M111.522 45.8106C111.888 46.9881 112.084 48.2401 112.084 49.5382C112.084 56.4629 106.47 62.0764 99.5462 62.0764C92.6219 62.0764 87.0079 56.4629 87.0079 49.5382C87.0079 42.6135 92.6219 37 99.5462 37C101.268 37 102.91 37.3472 104.403 37.9755"
                        stroke="#171822"
                        stroke-width="2.5"
                        stroke-linecap="round"
                      />
                      <path
                        d="M109.825 42.3565C109.156 41.4005 108.357 40.542 107.453 39.8069"
                        stroke="#171822"
                        stroke-width="2.5"
                        stroke-linecap="round"
                      />
                      <path
                        d="M98.7554 43.0999V50.1574C98.7554 50.2107 98.7983 50.2539 98.8525 50.2539H104.855"
                        stroke="#1B9AAA"
                        stroke-width="2.5"
                        stroke-linecap="round"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M137.872 55.3191C140.221 55.3191 142.127 57.2246 142.127 59.5744C142.127 61.9242 140.221 63.8297 137.872 63.8297C135.522 63.8297 133.616 61.9242 133.616 59.5744C133.616 57.2246 135.522 55.3191 137.872 55.3191Z"
                        fill="#DCEEF8"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M149.788 41.702C153.078 41.702 155.745 44.3693 155.745 47.6595C155.745 50.9497 153.078 53.6169 149.788 53.6169C146.498 53.6169 143.831 50.9497 143.831 47.6595C143.831 44.3693 146.498 41.702 149.788 41.702Z"
                        fill="#DCEEF8"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M130.212 40C131.153 40 131.914 40.7617 131.914 41.7021C131.914 42.6426 131.153 43.4043 130.212 43.4043C129.272 43.4043 128.51 42.6426 128.51 41.7021C128.51 40.7617 129.272 40 130.212 40Z"
                        fill="#DCEEF8"
                      />
                    </svg>
                    <h5 class="mt-2">No Timesheets</h5>
                    <div style="height: 100px"></div>
                  </div>
                </b-overlay>
              </div>
              <div class="" v-else>
                <b-overlay :show="unsubmittedLoading" rounded="sm" class="mt-1">
                  <b-table
                    ref="unsubmitted"
                    :fields="fields"
                    :items="unsubmittedList"
                    hover
                    class="mobile_table_css"
                    responsive
                  >
                    <template #cell(time)="data">
                      <div
                        v-if="
                          data.item.shift.start !== data.item.start ||
                          data.item.shift.end !== data.item.end
                        "
                        class="pt-3"
                      >
                        <del>
                          {{ data.item.time }}
                        </del>
                        <br />
                        <span
                          class="alert alert-primary d-flex font-weight-bold"
                          style="padding: 5px"
                        >
                          <span class="text-dark"
                            ><feather-icon
                              size="15"
                              class=""
                              icon="Edit2Icon"
                            />
                            {{ momentFormat(data.item.start, "HH:mm") }} -
                            {{ momentFormat(data.item.end, "HH:mm") }}
                          </span>
                        </span>
                      </div>
                      <div v-else>
                        {{ data.item.time }}
                      </div>
                    </template>
                    <template #cell(commentary)="data">
                      <div style="width: 150px">{{ data.item.commentary }}</div>
                      <!--                          <b-row class="center text-primary">-->
                      <!--                              <feather-icon v-b-tooltip.hover="data.item.commentary" size="20" class="" icon="MessageCircleIcon"/>-->
                      <!--                          </b-row>-->
                    </template>
                    <template #cell(actions)="data">
                      <b-row class="center text-primary">
                        <b-button
                          size="sm"
                          variant="outline-white"
                          class="bg-primary text-white"
                          @click="approveUnsubmitted(data.item)"
                        >
                          Approve & Submit
                        </b-button>
                      </b-row>
                    </template>
                  </b-table>
                </b-overlay>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import {
  BNavItemDropdown,
  BTable,
  BPagination,
  BBadge,
  BLink,
  BAvatar,
  BButton,
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BForm,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BListGroup,
  BListGroupItem,
  BAvatarGroup,
  VBTooltip,
  BOverlay,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Ripple from "vue-ripple-directive";
import DropdownMenue from "./dropDownMenu.vue";
import MomentMixin from "@/mixins/MomentMixin";
import TimesheetAPI from "@/apis/modules/timesheet";
import ErrorMessages from "@/mixins/ErrorMessages";
import SuccessMessage from "@/mixins/SuccessMessage";
import Filter from "@/mixins/FilterMixin";
import DateDiff from "date-diff";

export default {
  components: {
    DropdownMenue,
    BRow,
    BCol,
    BTable,
    BPagination,
    BNavItemDropdown,
    BBadge,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
    BCard,
    BForm,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BListGroup,
    BListGroupItem,
    BAvatarGroup,
    VBTooltip,
    BOverlay,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  mixins: [ErrorMessages, SuccessMessage, MomentMixin, Filter],
  data() {
    return {
      haveData: true,
      users: [],
      selectedUserId: 0,
      unsubmittedList: [],
      selectedUser: {},
      unsubmittedIds: [],
      tableLoading: false,
      unsubmittedLoading: false,
      filters: {},
      filter: null,
      filterOn: [],
      fieldsUserList: [
        {
          key: "user",
        },
      ],
      fields: [
        {
          key: "date",
          label: "DATE",
          // sortable: true
        },
        {
          key: "programme",
          label: "PROGRAMME",
        },
        {
          key: "time",
          label: "TIME",
        },
        {
          key: "break",
          label: "BREAK",
        },
        {
          key: "hours",
          label: "HOURS",
        },
        {
          key: "commentary",
          label: "COMMENTARY",
        },
        {
          key: "actions",
          label: "",
        },
      ],
    };
  },
  methods: {
    async getUsers() {
      try {
        this.tableLoading = true;
        const Response = await TimesheetAPI.getUnSubmittedUsers(
          this.filterQuery
        );
        const dataArray = Response.data.data.map((x) => ({
          id: x.id,
          user: x.first_name + " " + x.last_name,
          avatar: "",
          count: x.shift_time_sheet_count,
        }));
        this.users = dataArray;
        this.tableLoading = false;
        return dataArray;
      } catch (error) {
        this.convertAndNotifyError(error);
        this.tableLoading = false;
      }
    },
    async getUnSubmittedTimesheetsOfUser(item) {
      try {
        this.unsubmittedLoading = true;
        this.selectedUser = item;
        const Response = await TimesheetAPI.getUnSubmittedTimesheetsOfUser(
          item.id
        );
        const dataArray = Response.data.data.map((x) => ({
          id: x.id,
          start: x.start,
          end: x.end,
          shift: x.shift,
          user_id: x.shift.assignee_id,
          date: `${this.momentFormat(x.start, "DD-MM-YYYY")}`,
          date_2: `${this.momentFormat(x.start, "DD/MM/YYYY")}`,
          time:
            `${this.momentFormat(x.shift.start, "HH:mm")}` +
            "-" +
            `${this.momentFormat(x.shift.end, "HH:mm")}`,
          hours: `${new DateDiff(new Date(x.end), new Date(x.start)).hours()}`,
          programme: x.shift.pcn_programme.name,
          break: x.shift.break_minutes ? x.shift.break_minutes : "N/A",
          commentary: x.commentary ? x.commentary : "-",
        }));
        const ids = [];
        dataArray.forEach(function (key) {
          ids.push(key.id);
        });
        this.unsubmittedIds = ids;
        this.unsubmittedList = dataArray;
        this.unsubmittedLoading = false;
        if (dataArray.length === 0) {
          this.haveData = true;
        } else {
          this.haveData = false;
        }
      } catch (error) {
        this.convertAndNotifyError(error);
        this.unsubmittedLoading = false;
      }
    },
    //For aprrove data load
    async getUnSubmittedTimesheetsOfUserMethodTwo(id) {
      try {
        this.unsubmittedLoading = true;
        const Response = await TimesheetAPI.getUnSubmittedTimesheetsOfUser(id);
        const dataArray = Response.data.data.map((x) => ({
          id: x.id,
          start: x.start,
          end: x.end,
          shift: x.shift,
          user_id: x.shift.assignee_id,
          date: `${this.momentFormat(x.start, "DD-MM-YYYY")}`,
          date_2: `${this.momentFormat(x.start, "DD/MM/YYYY")}`,
          time:
            `${this.momentFormat(x.shift.start, "HH:mm")}` +
            "-" +
            `${this.momentFormat(x.shift.end, "HH:mm")}`,
          hours: `${new DateDiff(new Date(x.end), new Date(x.start)).hours()}`,
          programme: x.shift.pcn_programme.name,
          break: x.shift.break_minutes ? x.shift.break_minutes : "N/A",
          commentary: x.commentary ? x.commentary : "-",
        }));
        const ids = [];
        dataArray.forEach(function (key) {
          ids.push(key.id);
        });
        this.unsubmittedIds = ids;
        this.unsubmittedList = dataArray;
        this.unsubmittedLoading = false;
        if (dataArray.length === 0) {
          this.haveData = true;
        } else {
          this.haveData = false;
        }
      } catch (error) {
        this.convertAndNotifyError(error);
        this.unsubmittedLoading = false;
      }
    },
    //single approved
    async approveUnsubmitted(item) {
      try {
        let payload = {};
        const ids = [];
        ids.push(item.id);
        payload.time_sheet_shift_ids = ids;
        await TimesheetAPI.unSubmittedtimesheetApprove(payload);
        this.showSuccessMessage(
          `Timesheet from ${item.date_2} was moved to Approved!`
        );
        this.getUnSubmittedTimesheetsOfUserMethodTwo(item.user_id);
        this.$refs["usersList"].refresh();
        this.$emit("dataRefresh");
        //this.unsubmittedLoading = false
      } catch (error) {
        this.convertAndNotifyError(error);
        //this.unsubmittedLoading = false
      }
    },
    // multiple approve
    async approveAllUnsubmitted() {
      try {
        this.unsubmittedLoading = true;
        let payload = {};
        payload.time_sheet_shift_ids = this.unsubmittedIds;
        await TimesheetAPI.unSubmittedtimesheetApprove(payload);
        this.showSuccessMessage(
          `All ${this.selectedUser.user}'s Timesheet were moved to Approved!`
        );
        this.$refs["usersList"].refresh();
        this.$emit("dataRefresh");
        //this.$refs['unsubmitted'].refresh()
        this.unsubmittedIds = [];
        this.haveData = true;
        this.unsubmittedLoading = false;
      } catch (error) {
        this.convertAndNotifyError(error);
        this.unsubmittedLoading = false;
      }
    },

    filterQueryUpdate() {
      this.$refs["usersList"].refresh();
    },
  },
  mounted() {
    this.getUsers();
  },
};
</script>
<style lang="scss">
.b-avatar-img {
  padding: 0px;
}
</style>
