<template>
  <b-sidebar
      id="user-profile-sidebar"
      :visible="isProfileSidebar"
      backdrop
      bg-variant="white"
      no-header
      right
      width="450px"
      shadow
      sidebar-class="sidebar-lg"
      @change="(val) => $emit('update:is-profile-sidebar', val)"
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h4 class="mb-0">
        </h4>

        <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
        />

      </div>

      <validation-observer ref="refFormObserver" >
        <b-card>

          <div class="row">
            <div class="col-12 d-flex justify-content-center">

                  <!-- <b-avatar
                      size="130px"
                      variant="light-primary"
                      badge
                      :src="require('@/assets/images/avatars/1-small.png')"
                      class="badge-minimal mt-n2 "
                      badge-variant="success"
                  /> -->
                  <b-avatar
                    size="130px"
                    variant="light-primary"
                    badge
                    class="badge-minimal mt-n2 "
                    badge-variant="success"
                    :src="getUserImage(userDetails.user_id)"
                  />

            </div>
          </div>

          <div class="col-12 d-flex justify-content-center mt-1">
            <h2 class=" font-weight-700 ">{{userDetails.user.first_name + ' ' + userDetails.user.last_name}}</h2>
          </div>
          <div class="col-12 d-flex justify-content-center">
            <b-alert   variant="primary" show>
              <small class="text-dark font-weight-700 mx-1 ">{{userDetails.job_role.name}}
              </small>
            </b-alert>
          </div>

          <div class="col-12 d-flex justify-content-start mt-1">
            <h5 class="mb-0 font-weight-700">Personal Info</h5>
          </div>

          <div class="col-12 d-flex justify-content-start">
            <div class="p-2 ml-n2">
              <div class="col-12 d-flex justify-content-start ">
                <feather-icon icon="PhoneIcon"/>
                <div class="ml-1"> {{userDetails.user.phone_number ? userDetails.user.phone_number : 'N/A'}}</div>
              </div>
              <div class="col-12 d-flex justify-content-start mt-1">
                <feather-icon icon="MailIcon"/>
                <div class="ml-1"> {{userDetails.user.email}}</div>
              </div>
            </div>
          </div>


          <div class="mt-n1"><hr></div>


          <div class="col-12 d-flex justify-content-start mt-2">
            <h5 class="mb-0 font-weight-700">Professional Info</h5>
          </div>

          <div class="col-12 d-flex justify-content-start">
            <div class="row p-1 ml-n2">
              <div class="col-12 d-flex justify-content-start">
                  <div class="col-md-6">
                    Qualification
                  </div>
                  <div class="col-md-6">
                    <h6 class="mb-0 font-weight-700">
                      {{userDetails.user.qalifications && userDetails.user.qalifications.qalification && userDetails.user.qalifications.qalification.name ? userDetails.user.qalifications.qalification.name: 'N/A'}}
                    </h6>
                  </div>
              </div>
              <div class="col-12 d-flex justify-content-start mt-1">
                  <div class="col-md-6">
                    Specialty
                  </div>
                  <div class="col-md-6">
                    <h6 class="mb-0 font-weight-700">
                      {{userDetails.user.qalifications && userDetails.user.qalifications.speciality && userDetails.user.qalifications.speciality.name ? userDetails.user.qalifications.speciality.name: 'N/A'}}
                    </h6>
                  </div>
              </div>
              <div class="col-12 d-flex justify-content-start mt-1">
                  <div class="col-md-6">
                    IT System
                  </div>
                  <div class="col-md-6">
                    <h6 class="mb-0 font-weight-700">
                      {{userDetails.user.qalifications && userDetails.user.qalifications.it_system && userDetails.user.qalifications.it_system.name ? userDetails.user.qalifications.it_system: 'N/A'}}
                    </h6>
                  </div>
              </div>
              <div class="col-12 d-flex justify-content-start mt-1">
                  <div class="col-md-6">
                    Smart card number
                  </div>
                  <div class="col-md-6">
                    <h6 class="mb-0 font-weight-700">
                      {{userDetails.user.qalifications && userDetails.user.qalifications.smart_card_number ? userDetails.user.qalifications.smart_card_number: 'N/A'}}
                    </h6>
                  </div>
              </div>
              <div class="col-12 mt-2" v-if="userDetails.user.qalifications && userDetails.user.qalifications.skills.length>0">
                  <div class="col-md-8">
                    Skills
                  </div>
                    <b-col class="font-weight-light" cols="12" v-if="userDetails.user.qalifications && userDetails.user.qalifications.skills">
                      <b-badge class="mr-1" variant="light-info" :key="skill" v-for="skill in userDetails.user.qalifications.skills">
                        <span class="text-primary">{{skill}}</span>
                      </b-badge>
                    </b-col> 
              </div>
              <div class="col-12 d-flex justify-content-start mt-1" v-else>
                  <div class="col-md-6">
                    Skills
                  </div>
                  <div class="col-md-6">
                    <h6 class="mb-0 font-weight-700">
                      N/A
                    </h6>
                  </div>
              </div>
            </div>
            <!-- <div class="p-2 ">
              <div class="col-12 d-flex justify-content-start">
                <h6 class="mb-0 font-weight-700">
                  {{userDetails.user.qalifications && userDetails.user.qalifications.qalification.name ? userDetails.user.qalifications.qalification.name: 'N/A'}}
                </h6>
              </div>
              <div class="col-12 d-flex justify-content-start mt-1">
                <h6 class="mb-0 font-weight-700">
                  {{userDetails.user.qalifications && userDetails.user.qalifications.speciality.name ? userDetails.user.qalifications.speciality.name: 'N/A'}}
                </h6>
              </div>
              <div class="col-12 d-flex justify-content-start mt-1">
                <h6 class="mb-0 font-weight-700">
                  {{userDetails.user.qalifications && userDetails.user.qalifications.it_system.name ? userDetails.user.qalifications.it_system.name: 'N/A'}}
                </h6>
              </div>
              <div class="col-12 d-flex justify-content-start mt-1">
                <h6 class="mb-0 font-weight-700">
                  {{userDetails.user.qalifications && userDetails.user.qalifications.smart_card_number ? userDetails.user.qalifications.smart_card_number: 'N/A'}}
                </h6>
              </div>
            </div> -->

          </div>

          <!-- <div class="col-12 d-flex justify-content-start mt-n2 ms-5">
            <div class="p-2 ml-n2 ms-5">
              <div class="col-12 d-flex justify-content-start ms-4">
                Skills
              </div>
            </div>
          </div>
          <div class="col-12 d-flex justify-content-start mt-n2">
            <div class="p-2 ml-n2">
              <div class="col-12 d-flex justify-content-start ">
                <b-row class="">
                  <b-col class="font-weight-light" cols="12" v-if="userDetails.user.qalifications && userDetails.user.qalifications.skills">
                    <b-badge class="mr-1" variant="light-info" :key="skill" v-for="skill in userDetails.user.qalifications.skills">
                      <span class="text-primary">{{skill}}</span>
                    </b-badge>
                  </b-col>
                  <b-col class="font-weight-light" cols="12" v-else>
                    <b-badge class="mr-1" variant="light-info">
                      <span class="text-primary">N/A</span>
                    </b-badge>
                  </b-col>

                </b-row>
              </div>
            </div>
          </div> -->
          <div class="mt-n1"><hr></div>


          <div class="col-12 d-flex justify-content-start mt-2">
            <h5 class="mb-0 font-weight-700"> Unavailability Today</h5>
          </div>

          <div class="col-12 d-flex justify-content-start">
            <div class="p-2 ml-n2">
              <div class="col-12 d-flex justify-content-start mb-1" :key="time" v-for="time in unavailabilityTimes">
                      <div><b-avatar variant="success" rounded size="10" >
                        <feather-icon icon="CheckIcon" size="1" />
                      </b-avatar></div>
                <span class="ml-1"> {{momentFormat(time.start, 'HH:mm')}} - {{momentFormat(time.end, 'HH:mm')}}</span>
              </div>
          </div>
          </div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            block
            class=""
            @click="viewProfile"
          >
            View Full Profile
          </b-button>
        </b-card>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>


import {
  BAlert,
  BButton,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BSidebar,
  BTab,
  BTabs,
  BCard,
  BAvatar,
  BBadge,
  BRow, BCol,

} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import draggable from 'vuedraggable'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import MomentMixin from '@/mixins/MomentMixin'

export default {
  mixins: [MomentMixin],
  components: {
    BAvatar,
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BButton,
    BFormDatepicker,
    BFormCheckbox,
    VuePerfectScrollbar,
    draggable,
    BTab,
    BTabs,
    BCard,
    BBadge,
    ValidationProvider,
    ValidationObserver,
    BRow, BCol,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isProfileSidebar',
    event: 'update:is-profile-sidebar'
  },
  props: {
    isProfileSidebar: {
      type: Boolean,
      required: true
    },
    userDetails: {
      type: Object,
      required: false
    },
    unavailabilityTimes: {
      type: Array,
      required: false
    },
  },
  methods: {
    toggle() {
      this.$emit('toggleSideBars')
    },
    viewProfile(){
      this.$router.push(`/staff-bank/${this.userDetails.user_id}/practice/${this.userDetails.practice_id}`)
    }
  },
  mounted(){
  },
  
  
}
</script>

<style lang="scss">

</style>
