import api from '../axios';

export default {
  getUsers: (filterQuery) =>
    api.get(`/api/pcn/time_sheets/users?${filterQuery}`),
  getUnapprovedTimesheetsOfUser: (id) =>
    api.get(`/api/pcn/time_sheets/users/${id}/un-approved`),
  getApprovedTimeSheets: (filterQuery) => api.get(`/api/pcn/time_sheets/users/approved?${filterQuery}`),
  submitUnApproved: (payload) =>
    api.post(`/api/pcn/time_sheets/users/un-approve`, payload),
  timesheetApprove: (payload) =>
    api.post('/api/pcn/time_sheets/users/approve', payload),
  getUserDetails: (id) => api.get(`/api/pcn/time_sheets/users/${id}/details`),
  getMyPcn: () => api.get('/api/pcn'),
  enableTimesheet: () => api.put('/api/pcn/time_sheets/users/enable'),
  getSummary: () => api.get('/api/pcn/time_sheets/summary'),
  getTimesheetEnableState: () => api.get('/api/pcn/time_sheets/is-enable'),

  getTimesheetsOfUser: (id) =>
    api.get(`/api/pcn/time_sheets/users/${id}/approve-and-un-approve`),


  /**Un-submitted routes */
  getUnSubmittedSummary: () => api.get('/api/pcn/un_submitted_time_sheets/summary'),
  getUnSubmittedUsers: (filterQuery) =>
  api.get(`/api/pcn/un_submitted_time_sheets/users?${filterQuery}`),
  getUnSubmittedTimesheetsOfUser: (id) =>
  api.get(`/api/pcn/un_submitted_time_sheets/users/${id}/un-submitted`),
  unSubmittedtimesheetApprove: (payload) =>
  api.post('/api/pcn/un_submitted_time_sheets/users/approve', payload),
};
