<template>
  <div class="home">
    <!-- <div class="mb-1">
      <div class="d-flex">
        <div class="mr-1">
            <b-button-group class="bg-white">
                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary">
                      Month
                </b-button>
                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-secondary">
                      Year
              </b-button>
            </b-button-group>
        </div>
        <div>
          <b-form-group>
            <v-select
              style="width: 210px;"
              class="bg-white"
              placeholder="This Week: Feb 22 - Feb 28"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              label="title3"
              :options="option"
            />
        </b-form-group>
        </div>

      </div>
    </div> -->

    <div class="float-right">
       <b-row>
<!--         <b-col cols="12"  xl="7" />-->
<!--         <b-col cols="12"  xl="5" class="pt-0 pb-2 pt-xl-2 pb-xl-0">-->
<!--            <b-row class="">-->
<!--                <b-col cols="12" md="7" xl="7" class="text-right mb-1">-->
<!--                  <div>-->
                    <!-- <b-button-group class="bg-white mr-1">
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary">
                              Month
                        </b-button>
                        <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-secondary">
                              Year
                      </b-button>
                    </b-button-group> -->
<!--                  </div>-->
<!--                </b-col>-->
<!--                <b-col cols="12" md="5" xl="5">-->
                  <!-- <b-form-group class="mr-1">
                      <v-select
                        style="width: 210px;"
                        class="bg-white"
                        placeholder="This Week: Feb 22 - Feb 28"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title3"
                        :options="option"
                      />
                  </b-form-group> -->
<!--                </b-col>-->
            </b-row>
<!--        </b-col>-->
<!--      </b-row>-->
      </div>

      <b-tabs lazy >
        <b-card class="">
        <b-tab>
          <template #title>
          <span class="" ><strong>Unsubmitted</strong>
          <span v-if="drafcount" class="ml-1 translate-middle badge rounded-circle bg-danger"
              style="font-size: 0.8em;">{{drafcount}}</span>
          </span>
        </template>
        <DraftApproval 
         @dataRefresh= "dataRefresh()"/>
        </b-tab>
          
        <b-tab>
          <template #title>
          <span class="" ><strong>Pending Approval</strong>
          <span v-if="pendingCount" class="ml-1 translate-middle badge rounded-circle bg-danger"
              style="font-size: 0.8em;">{{pendingCount}}</span>
          </span>
        </template>
        <PendingApproval 
         @dataRefresh= "dataRefresh()"/>
        </b-tab>
        
        <b-tab title="Approved">
          <div class="alert-body"><Approved /></div>
        </b-tab>

           </b-card>
      </b-tabs>


 </div>
</template>

<script>

import { BCard, BTabs , BTab, BAvatar, BButtonGroup,
    BButton, BRow,
    BCol,} from 'bootstrap-vue'
import Approved from './views/approved.vue'
import PendingApproval from './views/pendingApproval.vue'
import DraftApproval from './views/draftApproval.vue'
import TimesheetAPI from '@/apis/modules/timesheet'
export default {
  name: 'Home',
  components: {
    PendingApproval,
    Approved,
    BCard,
    BTabs,
    BTab,
    BAvatar,
    BButtonGroup,
    BButton,
    BRow,
    BCol,
    DraftApproval
  },
  data(){
    return{
      pendingCount:0,
      drafcount:0,
      option: [{title3: 'Feb 15 - Feb 21'},{title3: 'Feb 8 - Feb 2'},{title3: 'Feb 1 - Feb 7'},{title3: 'Jan 25 - Jan 31'},],
      
    }
  },
  methods:{
    async getSummary(){
      try{
        const Response = (await TimesheetAPI.getSummary()).data.data
        this.pendingCount = Response.pending_approval_count
      }catch(error){
        this.convertAndNotifyError(error)
      }
    },
    async getunSubmittedSummary(){
      try{
        const Response = (await TimesheetAPI.getUnSubmittedSummary()).data.data
        this.drafcount = Response.draf_approval_count
      }catch(error){
        this.convertAndNotifyError(error)
      }
    },
    dataRefresh(){
      this.getSummary()
      this.getunSubmittedSummary()
    }
  },
  mounted(){
    this.getSummary()
    this.getunSubmittedSummary()
  }
}
</script>
